import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import ButtonCircle from './Buttons/ButtonCircle'
import { Current } from './Shared'

import { generatePath } from './../utils/helpers'
import Line from './Line'

const Wrapper = styled(motion.div)`
    text-align: left;

    padding: 0 3rem;

    ${props => props.theme.above.desktop`
        width: 100%;
        max-width: 55rem;

        margin: 0 auto;
        padding: 0;
    `}
`

const Subtitle = styled(motion.span)`
    display: block;

    margin-bottom: 1rem;
    
    font-family: ${props => props.theme.fontFamily.neueMontrealRegular};
    letter-spacing: 0.12rem;

    ${props => props.theme.above.desktop`
        font-size: 1.4rem;
    `}
`

const TitleWrapper = styled.div`
    position: relative;

    overflow: hidden;

    margin-bottom: 1rem;
`

const Title = styled(motion.h3)``

const Description = styled(motion.div)`
    margin-bottom: 2rem;

    p {
        margin-bottom: 2rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ${props => props.theme.above.desktop`
        max-width: 40rem;
    `}
`

const StyledButtonCircle = styled(ButtonCircle)`
    display: none;

    ${props => props.theme.above.desktop`
        display: inline-block;
    `}
`

const titleVariants = {
    before: {
        opacity: 0,
        skewY: 3,
        y: 100
    },
    after: {
        opacity: 1,
        skewY: 0,
        y: 0
    }
}

const transition = {
    duration: 0.5,
    damping: 100,
    stiffness: 100,
    ease: 'circOut'
}

const TitleWithButton = ({
    lang, 
    className,
    indication,
    title,
    subtitle,
    description,
    animate,
    button: {
        type,
        direction,
        label,
        to,
        onClick
    }
}) => {

    const [ref, inView] = useInView({
        triggerOnce: false
    })

    const animation = inView ? 'after' : 'before'

    return (
        <Wrapper ref={ref} className={className}>
            {indication && (
                <Current 
                    current={indication.current} 
                    total={indication.total} 
                    animation={animation}
                    variants={{
                        before: {
                            opacity: 0,
                            x: -25
                        },
                        after: {
                            opacity: 1,
                            x: 0
                        }
                    }}
                    transition={transition}
                />
            )}
            {subtitle && (
                <Subtitle
                    animate={animation}
                    variants={{
                        before: {
                            opacity: 0,
                            x: -25
                        },
                        after: {
                            opacity: 1,
                            x: 0
                        }
                    }}
                    transition={transition}
                >
                    <Line />{subtitle}
                </Subtitle>
            )}
            <TitleWrapper>
                <Title 
                    className='title'
                    animate={animation}
                    variants={titleVariants}
                    transition={transition}
                >{title}</Title>
            </TitleWrapper>
            <Description 
                className='description' 
                dangerouslySetInnerHTML={{ __html: description }}
                animate={animation}
                initial='before'
                variants={{
                    before: {
                        opacity: 0,
                        y: -25
                    },
                    after: {
                        opacity: 1,
                        skewY: 0,
                        y: 0
                    }
                }}
                transition={{
                    duration: 0.5,
                    delay: 0.15,
                    damping: 100,
                    stiffness: 100,
                    ease: 'circOut'
                }}
            />
            {to && <StyledButtonCircle type={type} direction={direction} label={label} to={generatePath(lang, to)} />}
            {onClick && <StyledButtonCircle type={type} direction={direction} label={label} onClick={onClick} />}
        </Wrapper>
    )
}

export default TitleWithButton
